<template>
  <v-row>
    <v-col
      cols="12"
      :md="filterShow === false ? 12 : 9"
      class="transition-ease-in-out"
    >
      <v-card>
        <v-card-title>
          {{ $t('messages.question') }}
        </v-card-title>
        <div class="table-toolbar">
          <remove
            v-if="selected.length > 0 && $can('question.delete')"
            @click="remove"
          />
          <add
            v-if="$can('question.create')"
            :to="{ name: 'dashboard.question-create', params: (this.$route.params.quiz_id) ? { quiz_id: this.$route.params.quiz_id } : '' }"
          />
          <filters
            v-model="filterShow"
            @click.stop="filterShow = !filterShow"
          />
        </div>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="questions.data"
          :server-items-length="questions.total"
          :options.sync="pagination"
          :items-per-page="parseInt(questions.per_page)"
          :footer-props="{ itemsPerPageOptions: [10, 25, 50, 100], showFirstLastPage: true, showCurrentPage: true, disableItemsPerPage: false }"
          :loading="loading"
          :show-select="!!$can('question.delete')"
          class="elevation-1"
          show-expand
          must-sort
          @sorted="saveOrder"
          @update:options="getQuestions"
        >
          <template #[`item.status`]="{ item }">
            <v-chip
              :color="$getColor(item.status)"
              dark
            >
              {{ item.status === true ? 'active' : 'inactive' }}
            </v-chip>
          </template>
          <template #[`item.image`]="{ item }">
            <img
              v-if="item.image"
              :src="$getFullPath(item.image)"
              style="width: 80px; height: 50px"
            >
          </template>
          <template #[`item.title`]="{ item }">
            {{ $strippedContent(item.title) }}
          </template>
          <template #[`item.created_at`]="{ item }">
            {{ item.created_at | moment('YYYY-MM-DD HH:mm') }}
          </template>
          <template #[`item.updated_at`]="{ item }">
            {{ item.updated_at | moment('YYYY-MM-DD HH:mm') }}
          </template>
          <template
            v-if="$can('question.update')"
            #[`item.action`]="{ item }"
          >
            <DataTableAction
              :text="$t('action.edit')"
              icon="mdi-pencil"
              @click="$router.push({ name: 'dashboard.question-update', params: { id: item.id }})"
            />
          </template>
          <template #expanded-item="{ item }">
            <td :colspan="headers.length">
              <h4
                v-if="item.answers.length"
                class="my-2"
              >
                Answers
              </h4>
              <ol class="pb-3">
                <li
                  v-for="answer in item.answers"
                  :key="answer.id"
                >
                  {{ answer.title }}
                  <v-chip
                    v-if="answer.is_correct"
                    color="green"
                    text-color="white"
                    small
                  >
                    Correct
                  </v-chip>
                </li>
              </ol>
            </td>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <v-col
      v-show="filterShow"
      cols="12"
      md="3"
    >
      <v-card>
        <v-card-title>
          {{ $t('action.filters') }}
        </v-card-title>
        <v-card-text>
          <v-select
            v-model="filter.quiz_id"
            :items="quizes"
            item-value="id"
            item-text="title"
            :label="$t('messages.quiz')"
            dense
            outlined
            clearable
            @change="getQuestions"
          >
            <template #selection="{ item }">
              {{ $strippedContent(item.title) }}
            </template>
            <template #item="{ item }">
              {{ $strippedContent(item.title) }}
            </template>
          </v-select>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import pagination from '@/mixins/pagination'
import DataTableAction from '@/components/table-actions/VDataTableAction'
import { add, remove, filters } from '@/components/actions'
import authHeader from '@/utils'
import Sortable from 'sortablejs'

export default {
  name: 'Question',
  components: {
    add, remove, DataTableAction, filters
  },
  directives: {
    sortableDataTable: {
      bind (el, binding, vnode) {
        const options = {
          animation: 150,
          onUpdate: function (event) {
            vnode.child.$emit('sorted', event)
          }
        }
        Sortable.create(el.getElementsByTagName('tbody')[0], options)
      }
    }
  },
  mixins: [pagination],
  data () {
    return {
      selected: [],
      filterShow: false,
      loading: true,
      headers: [
        { text: '#', value: 'id' },
        { text: this.$t('messages.title'), value: 'title' },
        { text: this.$t('messages.status'), value: 'status' },
        { text: this.$t('messages.image'), value: 'image', sortable: false },
        { text: this.$t('messages.quiz'), value: 'quiz.title' },
        { text: this.$t('messages.created_at'), value: 'created_at' },
        { text: this.$t('messages.updated_at'), value: 'updated_at' },
        this.$can('question.update') ? { text: this.$t('messages.actions'), value: 'action', sortable: false, width: '15px', align: 'right' } : {},
        { text: '', value: 'data-table-expand' }
      ],
      questions: {
        data: []
      },
      quizes: [],
      filter: {}
    }
  },
  beforeMount () {
    this.getQuizes()
  },
  methods: {
    saveOrder (event) {
      const movedItem = this.questions.data.splice(event.oldIndex, 1)[0]
      this.questions.data.splice(event.newIndex, 0, movedItem)
      // todo do request to save order, implement in backend
    },
    getQuestions () {
      let filter = ''
      if (this.filter.quiz_id) {
        filter += '&quiz_id=' + this.filter.quiz_id
      }
      let filterByQuiz = ''
      if (this.$route.params.quiz_id !== undefined) {
        filterByQuiz = '&quiz_id=' + this.$route.params.quiz_id
      }
      this.$http
        .get('/questions' + this.datatableOptions + filterByQuiz + filter, { headers: authHeader() })
        .catch(error => this.displayError(error))
        .then((response) => {
          this.loading = false
          this.questions = response.data
        })
    },
    getQuizes () {
      this.$http
        .get('/quizes/?take=500', { headers: authHeader() })
        .catch(error => this.displayError(error))
        .then(({ data: { data } }) => {
          this.quizes = data
        })
    },
    remove () {
      this.$http
        .delete('/questions', { data: { id: this.selected.map(val => val.id) }, headers: authHeader() })
        .catch(error => this.displayError(error))
        .then(() => {
          // remove ids from current elements
          this.questions.data = this.questions.data.filter((el) => !this.selected.map(val => val.id).includes(el.id))
          this.selected = []
          this.$store.commit('setNotification', { color: 'error', message: this.$t('action.delete_success') })
        })
    }
  }
}
</script>
