var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{staticClass:"transition-ease-in-out",attrs:{"cols":"12","md":_vm.filterShow === false ? 12 : 9}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('messages.question'))+" ")]),_c('div',{staticClass:"table-toolbar"},[(_vm.selected.length > 0 && _vm.$can('question.delete'))?_c('remove',{on:{"click":_vm.remove}}):_vm._e(),(_vm.$can('question.create'))?_c('add',{attrs:{"to":{ name: 'dashboard.question-create', params: (this.$route.params.quiz_id) ? { quiz_id: this.$route.params.quiz_id } : '' }}}):_vm._e(),_c('filters',{on:{"click":function($event){$event.stopPropagation();_vm.filterShow = !_vm.filterShow}},model:{value:(_vm.filterShow),callback:function ($$v) {_vm.filterShow=$$v},expression:"filterShow"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.questions.data,"server-items-length":_vm.questions.total,"options":_vm.pagination,"items-per-page":parseInt(_vm.questions.per_page),"footer-props":{ itemsPerPageOptions: [10, 25, 50, 100], showFirstLastPage: true, showCurrentPage: true, disableItemsPerPage: false },"loading":_vm.loading,"show-select":!!_vm.$can('question.delete'),"show-expand":"","must-sort":""},on:{"update:options":[function($event){_vm.pagination=$event},_vm.getQuestions],"sorted":_vm.saveOrder},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.$getColor(item.status),"dark":""}},[_vm._v(" "+_vm._s(item.status === true ? 'active' : 'inactive')+" ")])]}},{key:"item.image",fn:function(ref){
var item = ref.item;
return [(item.image)?_c('img',{staticStyle:{"width":"80px","height":"50px"},attrs:{"src":_vm.$getFullPath(item.image)}}):_vm._e()]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$strippedContent(item.title))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at,'YYYY-MM-DD HH:mm'))+" ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.updated_at,'YYYY-MM-DD HH:mm'))+" ")]}},(_vm.$can('question.update'))?{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('DataTableAction',{attrs:{"text":_vm.$t('action.edit'),"icon":"mdi-pencil"},on:{"click":function($event){return _vm.$router.push({ name: 'dashboard.question-update', params: { id: item.id }})}}})]}}:null,{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"colspan":_vm.headers.length}},[(item.answers.length)?_c('h4',{staticClass:"my-2"},[_vm._v(" Answers ")]):_vm._e(),_c('ol',{staticClass:"pb-3"},_vm._l((item.answers),function(answer){return _c('li',{key:answer.id},[_vm._v(" "+_vm._s(answer.title)+" "),(answer.is_correct)?_c('v-chip',{attrs:{"color":"green","text-color":"white","small":""}},[_vm._v(" Correct ")]):_vm._e()],1)}),0)])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.filterShow),expression:"filterShow"}],attrs:{"cols":"12","md":"3"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('action.filters'))+" ")]),_c('v-card-text',[_c('v-select',{attrs:{"items":_vm.quizes,"item-value":"id","item-text":"title","label":_vm.$t('messages.quiz'),"dense":"","outlined":"","clearable":""},on:{"change":_vm.getQuestions},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$strippedContent(item.title))+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$strippedContent(item.title))+" ")]}}]),model:{value:(_vm.filter.quiz_id),callback:function ($$v) {_vm.$set(_vm.filter, "quiz_id", $$v)},expression:"filter.quiz_id"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }